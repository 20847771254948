<template>
  <!--begin::Exolore drawer toggle-->
  <button
  ref="side_button"
    id="kt_explore_toggle"
    class="
      btn btn-sm btn-white btn-active-primary
      shadow-sm
      position-fixed
      px-5
      fw-bolder
      zindex-2
      top-50
      mt-10
      end-0
      transform-90
      fs-6
      invisible
    "
    data-bs-toggle="tooltip"
    data-bs-placement="right"
    data-bs-trigger="hover"
  >
    <span id="kt_explore_toggle_label">Explore</span>
  </button>
  <!--end::Exolore drawer toggle-->

  <!--begin::Exolore drawer-->
  <div
    id="kt_explore"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="explore"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'350px', 'lg': '530px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_explore_toggle"
    data-kt-drawer-close="#kt_explore_close"
  >
    <!--begin::Card-->
    <div class="card shadow-none w-100">
      <!--begin::Header-->
      <div class="card-header  min-h-90px" id="kt_explore_header">
        <h3 class="card-title fw-bolder text-gray-700">
          <img
                        :src="require(`@/assets/images/img/comment_box_icon.svg`)"
                        class="me-3"
                      />
                    Answer Detail
        </h3>

        <div class="card-toolbar">
          <a href="#" class="btn btn-sm btn-light me-2 w-90px ms-10"><!--begin::Svg Icon | path: icons/duotune/arrows/arr012.svg--><span class="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black"></path><rect x="6" y="12" width="7" height="2" rx="1" fill="black"></rect><rect x="6" y="7" width="12" height="2" rx="1" fill="black"></rect></svg></span><!-- &lt;/span&gt; --><!--end::Svg Icon--><!--begin::Indicator--><span class="indicator-label">{{getSerchQuestion.total_count}}</span><!--end::Indicator--></a>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body" id="kt_explore_body">
        <!--begin::Content-->
        <div
          id="kt_explore_scroll"
          class="scroll-y me-n5 pe-5"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_explore_body"
          data-kt-scroll-dependencies="#kt_explore_header"
          data-kt-scroll-offset="5px"
        >
          <!--begin::Wrapper-->
          <div class="mb-0">
            <!--begin::Header-->
            <div class="mb-7">
              <div class="">
                <h3 class="mb-0">{{getSerchQuestion.question}}</h3>
                <div class="mt-3 fw-bolder ms-1 text-gray-400 text-hover-primary mb-2">{{getSerchQuestion.description}}</div>
              </div>
            </div>
            <!--end::Header-->

            <!--begin::License-->
            <div
              class="
                rounded
                border border-dashed border-gray-300
                py-4
                px-6
                mb-5
              "
              v-for="(q,index) in questionData" :key="index"
            >
              <div class="d-flex flex-stack">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-1">
                    <div class="fs-6 fw-bold fw-bold mb-0 me-1 pt-1">
                      {{q.response}}
                    </div>
                  </div>
                  <div class="fs-7 text-muted fw-bold mt-1 mb-1">
                    {{q.quizentries ? q.quizentries.first_name : ''}} {{q.quizentries ? q.quizentries.last_name : ''}} &nbsp;&nbsp;  {{q.quizentries ? q.quizentries.email : ''}}
                  </div>
                </div>
              </div>
            </div>
            <!--begin::Purchase-->
            <!--end::Purchase-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
  <!--end::Exolore drawer-->
</template>

<script>
import { defineComponent } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";
import {mapActions,mapGetters} from "vuex"
export default defineComponent({
  name: "kt-explore",
  setup() {
    const demos = [
      {
        name: "demo1",
        available: true,
      },
      {
        name: "demo2",
        available: true,
      },
      {
        name: "demo3",
      },
      {
        name: "demo4",
      },
      {
        name: "demo5",
      },
      {
        name: "demo6",
      },
      {
        name: "demo7",
        available: true,
      },
      {
        name: "demo8",
      },
      {
        name: "demo9",
      },
      {
        name: "demo10",
      },
      {
        name: "demo11",
      },
      {
        name: "demo12",
      },
      {
        name: "demo13",
      },
    ];

    return {
      themeName,
      demos,
      demo,
    };
  },
  data(){
    return{
      questionData:[]
    }
  },
  computed:{
    ...mapGetters({
      getCounter:'Quiz/getCounter',
      getSerchQuestion:'Quiz/getSerchQuestion'
    })
  },
  watch:{
    getCounter(){
      this.$refs.side_button.click()
      this.getdata()
    }
  },
  methods:{
    ...mapActions({
      getQuestionResponse:'Quiz/getQuestionResponse'
    }),
    getdata(){
       this.getQuestionResponse(this.getSerchQuestion.id).then((res)=>{
          if(res.data.status==true){
              this.questionData=res.data.data
          }
        })
    }
  },
});
</script>
