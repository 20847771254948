<template>
  <!--begin::Menu-->
  <div
    class="
      menu
      menu-sub
      menu-sub-dropdown
      menu-column
      menu-rounded
      menu-gray-600
      menu-state-bg-light-primary
      fw-bold
      py-4
      fs-6
      w-275px
    "
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <img alt="Logo" src="/media/avatars/blank.png" />
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{user.first_name}} {{user.last_name}} 
            <span
              class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
              >{{user.type}}</span
            >
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7"
            >{{user.email}}</a
          >
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <RouterLink :to="{name : 'Onboarding'}" class="menu-link px-5">Onboarding Library</RouterLink>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <RouterLink :to="{name : 'Splash'}" class="menu-link px-5">Splash Screen</RouterLink>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="doLogout()" class="menu-link px-5"> Sign Out </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script>
import { defineComponent } from "vue";
import { mapActions,mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { RouterLink } from "vue-router";
export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
       const toast = useToast();
        return {toast};
    },
  computed: {
        ...mapGetters({
            user: "Auth/user",
        }),
    },
     methods: {
        ...mapActions({
            logoutuser: "Auth/logout",
        }),
        doLogout(){
          this.logoutuser()
          // this.$router.push({name: 'login'})
                // .then(() => {
                //    this.$router.push({name: 'login'})
                // })
                // .catch((error) => {
                //   console.log(error)
                // });
        }
     }
});
</script>
<style>
.menu{
  z-index: 110 !important;
}
</style>
