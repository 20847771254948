
import { defineComponent } from "vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { RouterLink } from "vue-router";

export default defineComponent({
    name: "kt-aside-primary",
    components: {
        // KTQuickLinksMenu,
        // KTNotificationsMenu,
        KTUserMenu,
    },
});
